import { sendEventToGA } from '@/managers/Analytics';
import { PbsKidsVideo, PbsKidsMultiPlatformSeries } from '@/types/pbskids-graph';

type VideoEventSrcData = {
  live: boolean
  mediaid: string
  show: string
  shownola: string
  station: string
  title: string
  type: string
}

const prepareEpisodeVideoType = (videoType: string, replacement = ''): string => {
  if (!replacement) return videoType;

  if ((/full_?(episode|length)/i).test(videoType)) {
    return replacement;
  }

  return videoType;
};

const getGAEventParams = (eventName: string, videoData: VideoEventSrcData, timeViewed: number) => {
  const eventParams = <Record<string, string|number>>{
    'event': eventName.toLowerCase(),
    'show_title': videoData.show || 'PBS KIDS',
    'station_localization': videoData.station || '',
    'video_player': videoData.live ? 'KIDS National Player Livestream' : 'KIDS National Player',
    'video_title': videoData.title || '',
    'video_tp_media_id': videoData.mediaid || '',
    'video_type': prepareEpisodeVideoType(videoData.type || '', 'full_length'),
  };

  if (timeViewed) {
    eventParams['video_streaming_duration'] = timeViewed;
  }

  return eventParams;
};

const trackVideoEvent = (eventName: string, videoData: VideoEventSrcData, timeViewed: number) => {
  const gaEventParams = getGAEventParams(eventName, videoData, timeViewed);
  sendEventToGA(gaEventParams);
};

const transformVideoDataForAnalytics = (video: PbsKidsVideo, station?: string, isLive?: boolean): VideoEventSrcData => {
  const property = video.properties?.length ? video.properties[0] as PbsKidsMultiPlatformSeries : null;
  return {
    live: isLive ?? false,
    mediaid: video.mediaManagerAsset?.legacy_tp_media_id ?? '',
    show: property?.title ?? '',
    shownola: property?.nolaRoot ?? '',
    station: station ?? '',
    title: video.mediaManagerAsset?.title ?? video.title ?? '',
    type: video.videoType ?? '',
  };
};

const triggerMediaStartGAEvent = (videoData: VideoEventSrcData): void => {
  trackVideoEvent('MediaStart', videoData, 0);
};

const triggerMediaStopGAEvent = (videoData: VideoEventSrcData, timeViewed: number): void => {
  trackVideoEvent('MediaStop', videoData, Math.round(timeViewed));
};

export {
  prepareEpisodeVideoType,
  triggerMediaStopGAEvent,
  triggerMediaStartGAEvent,
  transformVideoDataForAnalytics,
};


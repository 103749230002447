import Logger from '@/utils/logger';

// GA = Google Analytics (4)

const logger = new Logger({ caller: 'managers.Analytics' });

const sendEventToGA = (eventData: Record<string, unknown>) => {
  logger.debug('sendEventToGA', eventData);
  const dataLayer = window.dataLayer = window.dataLayer || [];
  dataLayer.push(eventData);
};

const clearGAEcommerceObj = () => {
  const dataLayer = window.dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
};

export {
  clearGAEcommerceObj,
  sendEventToGA,
};

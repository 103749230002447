import { useRouter } from 'next/router';
import { useContext, useEffect, useRef } from 'react';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import {
  matchElemToPageModuleData,
  TrackableModulesType,
  trackModuleView,
} from '@/managers/Analytics/pageModuleEvents';
import { BodyContentModuleType } from '@/components/modules/BodyContentModule';
import { MastheadContentModule, PbsKidsCatalogMediaCollection } from '@/types/pbskids-graph';

export function useGAViewListTracker(
  modulesData: (BodyContentModuleType|MastheadContentModule|PbsKidsCatalogMediaCollection)[],
  moduleCategory: string,
) {
  const router = useRouter();
  const { station, stationError } = useContext(StationLocalizationContext);
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (
      !modulesData?.length ||
      !moduleCategory ||
      (!station?.callSign && !stationError)
    ) {
      return;
    }

    let observer: IntersectionObserver|null = null;

    const handleIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const moduleData = matchElemToPageModuleData(entry.target, modulesData as TrackableModulesType[]);
          if (moduleData) {
            trackModuleView(entry.target, moduleData, moduleCategory, station.callSign || '');
          }
          // Just record each view the once.
          observer.unobserve(entry.target);
        }
      });
    };

    const onPageLoad = () => {
      if (!firstRenderRef.current) return;

      firstRenderRef.current = false;
      observer = new IntersectionObserver(handleIntersect);

      const observables = Array.from(document.querySelectorAll('[data-ga-view-list-module]'));

      observables.forEach((el) => {
        // Watch for module entring view
        observer?.observe(el);
      });
    };

    const onRouteChangeStart = () => {
      observer?.disconnect();
    };

    const onRouteChangeComplete = () => {
      firstRenderRef.current = true;
      window.setTimeout(onPageLoad, 100);
    };

    onPageLoad();
    // Ensure module views are still tracked after route changes.
    router.events?.on('routeChangeStart', onRouteChangeStart);
    router.events?.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      observer?.disconnect();
      observer = null;
      router.events?.off('routeChangeStart', onRouteChangeStart);
      router.events?.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [ modulesData, moduleCategory, station, router, stationError ]);
}


import { useContext, useEffect } from 'react';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import {
  handleTrackedModuleItemClick,
  matchElemToPageModuleData,
  TrackableModulesType,
} from '@/managers/Analytics/pageModuleEvents';
import { BodyContentModuleType } from '@/components/modules/BodyContentModule';
import { MastheadContentModule, PbsKidsCatalogMediaCollection } from '@/types/pbskids-graph';

export function useGACTAClickTracker(
  modulesData: (BodyContentModuleType|MastheadContentModule|PbsKidsCatalogMediaCollection)[],
  moduleCategory: string,
) {
  const { station, stationError } = useContext(StationLocalizationContext);

  useEffect(() => {
    const body = document.body;

    if (
      !body ||
      !modulesData?.length ||
      !moduleCategory ||
      (!station?.callSign && !stationError)
    ) {
      return;
    }

    const handlePageClick = (ev: Event) => {
      const target = ev.target as HTMLElement;

      if (target.closest('[data-ga-click-ignore]')) return;

      const parent = target.parentNode as HTMLElement;
      const list = parent?.closest('[data-ga-view-list-module]');
      const moduleEl = list || target.closest('[data-ga-cta-click-module]');

      if (!moduleEl) return;

      const moduleData = matchElemToPageModuleData(moduleEl, modulesData as TrackableModulesType[]);
      if (moduleData) {
        handleTrackedModuleItemClick(moduleData, moduleCategory, station?.callSign || '', !!list, ev);
      }
    };

    body.addEventListener('click', handlePageClick, true);

    return () => {
      body.removeEventListener('click', handlePageClick, true);
    };
  }, [ modulesData, moduleCategory, station, stationError ]);
}


import React from 'react';
import styles from './HeaderModule.module.scss';
import { Maybe } from '@/types/pbskids-graph';

interface Props {
  heading?: Maybe<string>
  body?: Maybe<string>
}

const HeaderModule: React.FC<Props> = ({
  heading,
  body,
}) => {
  return ( (heading || body) &&
    <section>
      {heading && <h2
        className={ styles.heading }
      >{ heading }</h2>}

      {body && <div
        className={ styles.body }
        dangerouslySetInnerHTML={{ __html: body }}
      ></div>}
    </section>
  );
};

export default HeaderModule;
